import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Burn Injury Lawyer In Connecticut | Bartlett & Grippe",
    description:
      "Don't face burn injuries in Connecticut alone. Bartlett & Grippe has helped many fire victims and can help determine if you have a case. Contact us today.",
    heroH1: "Burn Injury lawyer In Connecticut",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Were you or a loved one injured by a fire?</h2>
            <p className="mb-8">
              Burn injury victims throughout Connecticut come to us dealing with
              physical pain and emotional trauma. Our experienced accident
              lawyers will spend time with you and your family members,
              educating you on the process and the options you have. We have
              successfully assisted many Connecticut fire victims. Let us help
              you determine if you have a case. You are under no obligation. We
              are here to help you and your family. Call us today.
            </p>
            <h2>
              Experienced CT trial lawyers representing burn injury victims
            </h2>
            <p className="mb-8">
              Fires can not only cause significant property damage, but also
              catastrophic and potentially fatal burn injuries. What initially
              seemed like an accidental fire can be traced to negligent and
              inattentive acts by a property owner or utility company, who
              should bear liability for fire-related harms. Bartlett Legal Group
              has represented many burn-related cases over the years. Using fire
              experts we try to determine liability caused by many factors such
              as negligence, improper town inspections, faulty work, defective
              products, and others. Call our experienced fire attorney Frank
              Bartlett now.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
            <p className="mb-8">
              Owners of apartment complexes must adhere to strict standards of
              safety for their tenants. When they ignore electrical problems or
              other defects, fires break out, resulting in more than one victim
              of the fire and subsequent burn injuries. Utility companies
              responsible for installing and grounding gas lines should be
              attending to every detail. The smallest oversight can have
              catastrophic consequences.
            </p>
            <p className="mb-8">
              We have handled complex cases involving substantial injuries and
              destruction of property as a result of fires. One such case
              involved a catastrophic fire at the Peachtree Apartment Complex in
              Norwich, Connecticut. Attorney Bartlett was the lead attorney,
              representing 52 tenants who were injured, displaced, or lost all
              of their possessions when a fire tore through the apartment
              buildings, spreading uncontrolled in the contiguous roof of the
              structure. Expert testimony showed that the fire was allowed to
              spread due to the lack of code mandated fire stops. Through the
              use of this expert testimony, as well as admissions elicited
              during depositions, a favorable confidential settlement was
              negotiated on behalf of all of the victims following a multi-day
              mediation.
            </p>
            <h2>Death Caused by Fire</h2>
            <p className="mb-8">
              Victims suffering physical and psychological scarring and families
              grieving a wrongful death need a legal voice. We will speak on
              your behalf in the pursuit of justice and attempt to maximum
              compensation.
            </p>
            <p className="mb-8">
              We are centrally located in Cheshire Connecticut.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
